export const colors = {
  mainColor: "#edf6ff",
  borderColor: "#d9f5ff",
  lightBg: "#f6f9fc",
  secondaryColor: "#3f51b5",
  secondary: "#87bbf1",
  textColor: "#425466",
  headingColor: "#001732",
  heading: "#244886",
  lightColor: "#fff",
  btnColor: "#02073E",
  cardText: "#cdd3e1",
  card1Btn: "#020738",
  card2Btn: "#e8f6ff",
  cardBtnHover: "#ecf2fa",
  blueColor: "#061299",
  error: "#a82e2e",
  success: "#198754",
};

export const strings = {
  currency: "FCFA",
};
